@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 4px;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0);    /* color of the scroll thumb */
  /* border: 3px solid gray;  creates padding around scroll thumb */
}

.btn {
    background: linear-gradient(
      92.23deg,
      #ff56f6 21.43%,
      #b936ee 50.63%,
      #3bace2 100%,
      #406aff 117.04%
    );
    box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
  }
  
  .btn:hover {
    box-shadow: 0 0 1px whitesmoke;
    color: white;
  }
  
  .gradient,
  .active {
    color: 
      #406aff
  }
  
  .active {
    color:#000;
    padding: .0rem;
    border-radius: 100%;
    font-weight: bold;
  }